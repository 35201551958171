:root {
    --color-background: black;
    --color-text: white;

    /* ICONS */
    --icon-expand-l: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 48 48'%3E%3Cpath d='M 7.484375 5.984375 A 1.50015 1.50015 0 0 0 6 7.6914062 L 6 15.5 A 1.50015 1.50015 0 1 0 9 15.5 L 9 11.121094 L 16.439453 18.560547 A 1.50015 1.50015 0 1 0 18.560547 16.439453 L 11.121094 9 L 15.5 9 A 1.50015 1.50015 0 1 0 15.5 6 L 7.6894531 6 A 1.50015 1.50015 0 0 0 7.484375 5.984375 z M 40.470703 5.9863281 A 1.50015 1.50015 0 0 0 40.308594 6 L 32.5 6 A 1.50015 1.50015 0 1 0 32.5 9 L 36.878906 9 L 29.439453 16.439453 A 1.50015 1.50015 0 1 0 31.560547 18.560547 L 39 11.121094 L 39 15.5 A 1.50015 1.50015 0 1 0 42 15.5 L 42 7.6894531 A 1.50015 1.50015 0 0 0 40.470703 5.9863281 z M 30.484375 29.984375 A 1.50015 1.50015 0 0 0 29.439453 32.560547 L 36.878906 40 L 32.5 40 A 1.50015 1.50015 0 1 0 32.5 43 L 40.310547 43 A 1.50015 1.50015 0 0 0 42 41.308594 L 42 33.5 A 1.50015 1.50015 0 1 0 39 33.5 L 39 37.878906 L 31.560547 30.439453 A 1.50015 1.50015 0 0 0 30.484375 29.984375 z M 17.470703 29.986328 A 1.50015 1.50015 0 0 0 16.439453 30.439453 L 9 37.878906 L 9 33.5 A 1.50015 1.50015 0 1 0 6 33.5 L 6 41.310547 A 1.50015 1.50015 0 0 0 7.6914062 43 L 15.5 43 A 1.50015 1.50015 0 1 0 15.5 40 L 11.121094 40 L 18.560547 32.560547 A 1.50015 1.50015 0 0 0 17.470703 29.986328 z'/%3E%3C/svg%3E");

    --icon-collapse-l: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 30 30'%3E%3Cpath d='M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 8.5859375 10 L 6 10 A 1.0001 1.0001 0 1 0 6 12 L 10.847656 12 A 1.0001 1.0001 0 0 0 12 10.847656 L 12 6 A 1.0001 1.0001 0 0 0 10.984375 4.9863281 A 1.0001 1.0001 0 0 0 10 6 L 10 8.5859375 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z M 24.980469 3.9902344 A 1.0001 1.0001 0 0 0 24.292969 4.2929688 L 20 8.5859375 L 20 6 A 1.0001 1.0001 0 0 0 18.984375 4.9863281 A 1.0001 1.0001 0 0 0 18 6 L 18 10.847656 A 1.0001 1.0001 0 0 0 19.152344 12 L 24 12 A 1.0001 1.0001 0 1 0 24 10 L 21.414062 10 L 25.707031 5.7070312 A 1.0001 1.0001 0 0 0 24.980469 3.9902344 z M 10.980469 17.990234 A 1.0001 1.0001 0 0 0 10.869141 18 L 6 18 A 1.0001 1.0001 0 1 0 6 20 L 8.5859375 20 L 4.2929688 24.292969 A 1.0001 1.0001 0 1 0 5.7070312 25.707031 L 10 21.414062 L 10 24 A 1.0001 1.0001 0 1 0 12 24 L 12 19.126953 A 1.0001 1.0001 0 0 0 10.980469 17.990234 z M 18.990234 17.990234 A 1.0001 1.0001 0 0 0 18 19.128906 L 18 24 A 1.0001 1.0001 0 1 0 20 24 L 20 21.414062 L 24.292969 25.707031 A 1.0001 1.0001 0 1 0 25.707031 24.292969 L 21.414062 20 L 24 20 A 1.0001 1.0001 0 1 0 24 18 L 19.117188 18 A 1.0001 1.0001 0 0 0 18.990234 17.990234 z'/%3E%3C/svg%3E");

    --icon-menu-l: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 50 50'%3E%3Cpath d='M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z'/%3E%3C/svg%3E");


}


body {
    margin: 0;
    font-family: 'SF-Pro-Text', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black;
}

.home-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.home-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*opacity: 0.5;*/
}


.bar {
    /*outline: 3px dotted red;*/
    position: fixed;
    width: 100%;
    min-height: 100px;
    left: 0;


    display: flex;
    justify-content: space-between;

}

.bar.header {
    top: 0;

    .right {
        padding-right: 20px;
    }

}

.bar.footer {
    bottom: 0;
}


.section {
    display: flex;
    align-items: center;
    justify-content: center;


    /*outline: 3px dotted red;*/
    min-height: 100px;
    min-width: 300px;
}

.section.right {
    justify-content: right;
}

.section.left {
    justify-content: left;
}

.block {
    display: flex;
    align-items: center;
    justify-content: center;

    /*outline: 3px dotted blue;*/
    min-height: 100px;
    min-width: 100px;
}


/*.right-body {*/
/*    !*outline: 3px dotted red;*!*/
/*    position: fixed;*/
/*    height: 100%;*/
/*    width: 500px;*/
/*    top: 0;*/
/*    right: 0;*/

/*    color: white;*/
/*    padding: 20px 20px 20px 0;*/


/*    !*padding: 20px 0;*!*/

/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/


/*    .bottom {*/
/*        !*outline: 3px dotted red;*!*/
/*        display: flex;*/
/*        flex-direction: row-reverse;*/
/*        text-align: right;*/
/*    }*/

/*}*/

.expand-wrapper {
    padding: 10px;
    /*outline: 3px dotted red;*/
    /*outline: 3px dotted red;*/
    display: inline-block;
    min-height: 56px;
    min-width: 56px;

    .icon {
        height: 36px;
        width: 36px;

        opacity: 0.2;
        transition: height 0.15s, width 0.15s, opacity 0.15s, -moz-transform ease-in-out 0.15s;
    }

    .icon:hover {
        cursor: pointer;
        height: 38px;
        width: 38px;
        opacity: 1;
    }

    .expand {
        background-image: var(--icon-expand-l);
    }

    .expand.fullscreen {
        background-image: var(--icon-collapse-l);
    }

    .icon:hover {
        cursor: pointer;
        height: 38px;
        width: 38px;
        opacity: 1;
    }

    /*.fullscreen {*/
    /*    background-color: red;*/
    /*}*/

}

.time-wrapper {
    /*outline: 3px dotted red;*/
    text-align: right;
    padding-right: 15px;
    /*outline: 3px dotted red;*/

    p {
        font-size: 2.5rem;
        line-height: 1.7em;
        color: white;
        margin: 0;
        padding: 0;
    }

    .ampm {
        /*vertical-align: top;*/
        /*outline: 3px dotted red;*/
        font-size: 1.25rem;
        opacity: 0.3;
        margin-left: -5px;
        /*position: relative;*/
        /*bottom: -1em;*/
        /*left: -5px;*/
    }

    .fullscreen {
        font-size: 5rem;
        /*padding-right: 10px;*/

        .ampm {
            font-size: 3rem;
            margin-left: -8px;
        }
    }


}


.center-body {
    /*outline: 3px dotted blue;*/
    position: fixed;
    height: 100%;
    max-width: 800px;
    top: 0;
    margin: 0 20px;
    /*top: calc(50% - 200px);*/
    /*left: calc(50% - 400px);*/

    display: flex; /* Add this */
    justify-content: center; /* Add this */
    align-items: center; /* Add this */
    text-align: center;
}


.quote-wrapper {
    /*outline: 3px dotted red;*/
    opacity: 0;

    animation: delayAppearAni 1s ease-out 1 forwards;
    -webkit-animation: delayAppearAni 1s ease-out 1 forwards;
    -moz-animation: delayAppearAni 1s ease-out 1 forwards;
    -o-animation: delayAppearAni 1s ease-out 1 forwards;

    h1 {
        font-size: 1.7rem;
        line-height: 1.7em;
        color: white;
        /*margin: 0;*/
        /*padding: 0;*/
        /*font-weight: bold;*/
        /*font-style: italic;*/
    }

    h2 {
        margin-top: 50px;
        font-size: 1rem;
        line-height: 1.8em;
        color: rgba(255, 255, 255, 1);
        /*margin: 0;*/
        /*padding: 0;*/
        font-style: italic;
    }

    a {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.3);
        font-style: italic;
    }

    a:hover {
        text-decoration: underline;
    }

}

@keyframes delayAppearAni {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
