a {
    text-decoration: none;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
html, body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: scroll; /* Prevents margin collapse issues and ensures consistent behavior */
}


textarea, input {
    outline: none;
}

button:focus {
    outline: 0;
}

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: normal;
    vertical-align: baseline;
    background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
    display: block;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

img,
object,
embed {
    max-width: 100%;
}

html {
    overflow-y: scroll;
}

ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted #000;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th {
    font-weight: bold;
    vertical-align: bottom;
}

td {
    font-weight: normal;
    vertical-align: top;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
}

input[type="radio"] {
    vertical-align: text-bottom;
}

input[type="checkbox"] {
    vertical-align: bottom;
}

.ie7 input[type="checkbox"] {
    vertical-align: baseline;
}

.ie6 input {
    vertical-align: text-bottom;
}

select, input, textarea {
    font: 99% sans-serif;
}

table {
    font-size: inherit;
    font-size: 100%;
}

small {
    font-size: 85%;
}

strong {
    font-weight: bold;
}

td, td img {
    vertical-align: top;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
    font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
    cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
    margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
    width: auto;
    overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
    -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/

/* let's clear some floats */
.clearfix:before, .clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}